.bc-ooh-rota {
  .govuk-table__header:nth-child(n + 2):nth-child(-n + 8) {
    width: 7%;
  }

  .govuk-table__header:nth-child(1) {
    width: 23%;
  }

  .govuk-table__header:nth-child(9) {
    width: 12%;
  }

  .govuk-table__header:nth-child(10) {
    width: 16%;
  }

  .govuk-table__body {
    .govuk-table__row:nth-last-of-type(2) {
      .govuk-table__cell {
        border-bottom: none;
        padding-bottom: 5px;
      }
    }

    .govuk-table__row:nth-of-type(2) {
      .govuk-table__cell {
        padding-top: 0;
        @include lbh-rem(font-size, 16);
      }
    }
  }
}

.bc-ooh-jobs {
  .govuk-table__cell:nth-child(1) {
    width: 14%;
  }

  .govuk-table__cell:nth-child(2) {
    width: 30%;
  }

  .govuk-table__cell:nth-child(3) {
    width: 40%;
  }

  .govuk-table__cell:nth-child(4) {
    width: 16%;
  }
}
