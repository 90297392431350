.bc-all-operatives {
  &__header {
    @include lbh-heading-h2;

    span {
      display: inline-block !important;
    }

    span:first-child {
      margin-right: govuk-spacing(3);
    }

    span:last-child {
      @extend .govuk-caption-l;
      @extend .lbh-caption;

      margin-top: 0;
    }
  }

  &__search {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: govuk-spacing(3);

    & > * {
      @include lbh-body-l;
      margin-top: govuk-spacing(1);
    }

    & > .lbh-search-box {
      label {
        @include govuk-visually-hidden;
      }

      input[type='search'] {
        @extend .govuk-input;
        @extend .lbh-input;
        @extend .govuk-input--width-10;
      }

      button {
        @extend .lbh-search-box__action;

        span {
          @include govuk-visually-hidden;
        }

        svg:nth-of-type(1) {
          display: block;
        }

        svg:nth-of-type(2) {
          display: none;
        }
      }

      input:not(:placeholder-shown) + button {
        svg:nth-of-type(1) {
          display: none;
        }

        svg:nth-of-type(2) {
          display: block;
        }
      }
    }
  }

  &__list {
    thead {
      th {
        @include lbh-rem(font-size, 14);
        @include lbh-rem(padding-right, 0);
        line-height: 1.4;
      }

      th:nth-child(1) {
        width: 190px;
      }

      th:nth-child(2) {
        width: 90px;
      }

      th:nth-child(3) {
        width: 80px;
      }

      th:nth-child(n + 4):nth-child(-n + 7) {
        width: 100px;
      }

      th:nth-child(8) {
        width: 200px;
      }

      button {
        @include lbh-rem(font-size, 14);

        appearance: none;
        background: none;
        border: none;
        color: lbh-colour('lbh-text');
        cursor: pointer;
        display: inline-flex;
        font-weight: 600;
        padding: 0 0 0 12px;

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='160' height='160' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%230B0C0C' d='m83.536 13.536 47.928 47.928A5 5 0 0 1 127.93 70H32.07a5 5 0 0 1-3.535-8.536l47.928-47.928a5 5 0 0 1 7.072 0ZM83.536 146.464l47.928-47.928A5 5 0 0 0 127.93 90H32.07a5 5 0 0 0-3.535 8.536l47.928 47.928a5 5 0 0 0 7.072 0Z'/%3E%3C/svg%3E%0A");
          background-size: 16px;
          background-repeat: no-repeat;
          background-position: -2px 1px;
          content: '';
          width: 12px;
          height: 20px;
          margin: 0 4px 0 -12px;
        }

        &.bc-sort {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='160' height='160' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%230B0C0C' d='m83.536 106.464 47.928-47.928A5 5 0 0 0 127.93 50H32.07a5 5 0 0 0-3.535 8.536l47.928 47.928a5 5 0 0 0 7.072 0Z' fill-rule='evenodd'/%3E%3C/svg%3E");
          }
        }

        &.bc-sort.bc-sort-desc {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='160' height='160' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%230B0C0C' d='m83.536 53.536 47.928 47.928A5 5 0 0 1 127.93 110H32.07a5 5 0 0 1-3.535-8.536l47.928-47.928a5 5 0 0 1 7.072 0Z'/%3E%3C/svg%3E");
          }
        }

        &:focus {
          background: lbh-colour('lbh-primary-focus');
          box-shadow: 0 -3px lbh-colour('lbh-primary-focus'),
            0 4px lbh-colour('lbh-text');
          outline: none;
        }
      }
    }

    tbody {
      td {
        @include lbh-rem(font-size, 14);
        @include lbh-rem(padding-right, 0);
        line-height: 1.4;
      }

      a {
        @extend .lbh-link;
        @extend .lbh-link--no-visited-state;
      }

      abbr {
        text-decoration: none;
      }
    }
  }

  .bc-summary-payband {
    display: inline-flex;
    padding-left: 10px;
    padding-right: 10px;

    span,
    button {
      justify-content: center;
      margin: 0;
      width: 80px;
    }

    span:nth-child(2) {
      width: 30px;
      margin: 0 -15px;
    }
  }
}
