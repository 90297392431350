$govuk-grid-widths: (
  one-fifth: 20%,
  one-quarter: 25%,
  one-third: 33.3333%,
  one-half: 50%,
  two-thirds: 66.6666%,
  three-quarters: 75%,
  four-fifths: 80%,
  full: 100%,
) !default;

@import 'lbh-frontend/lbh/all';
@import 'environments';
@import 'all-operatives';
@import 'authorisations';
@import 'bonus-summary';
@import 'close-week';
@import 'close-period';
@import 'open-weeks';
@import 'out-of-hours';
@import 'overtime';
@import 'pay-elements';
@import 'search';
@import 'projections';
@import 'band-changes';

/* Bug fixes - remove when fixed in lbh-frontend */

.lbh-tabs {
  .govuk-tabs__list-item--selected {
    margin-top: -5px;
  }

  .govuk-tabs__tab {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.lbh-back-link {
  border-bottom: 1px solid transparent;

  &:visited {
    color: lbh-colour('lbh-link');
  }

  &:hover {
    color: lbh-colour('lbh-link-hover');
  }

  @include govuk-media-query($media-type: print) {
    display: none !important;
  }
}

.lbh-link--no-visited-state {
  &:hover {
    color: lbh-colour('lbh-link-hover');
  }
}

.lbh-simple-pagination__link {
  padding: 15px 0px 10px 20px;

  svg {
    left: 0px;
    right: inherit;
  }
}

.lbh-simple-pagination__link--next {
  padding: 15px 20px 10px 0px;

  svg {
    left: inherit;
    right: 0px;
  }
}

.lbh-header,
.lbh-phase-banner,
.lbh-announcement {
  @include govuk-media-query($media-type: print) {
    display: none !important;
  }
}

.lbh-main-wrapper {
  @include govuk-media-query($media-type: print) {
    padding-top: 0 !important;
  }
}

/* Custom widths */

.govuk-\!-width-six-tenths {
  width: 60% !important;
}

.govuk-\!-width-five-tenths {
  width: 50% !important;
}

.govuk-\!-width-four-tenths {
  width: 40% !important;
}

.govuk-\!-width-three-tenths {
  width: 30% !important;
}

.govuk-\!-width-two-tenths {
  width: 20% !important;
}

.govuk-\!-width-one-tenth {
  width: 10% !important;
}

/* Table customisations */

.govuk-table-s {
  td,
  th {
    @include lbh-body-s;
  }
}

.govuk-table {
  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.govuk-table__foot {
  .govuk-table__cell:not(.govuk-table__cell--numeric),
  .govuk-table__header {
    border-bottom: 0;
  }
}

/* Font customisations */

.lbh-\!-font-colour-warning {
  color: lbh-colour('lbh-error') !important;
}
