.bc-open-weeks {
  & > h1 {
    @include lbh-heading-h2;
  }

  & > p {
    @include lbh-body-m;
  }

  &__period {
    header {
      h2 {
        @include lbh-body-l;
      }

      p {
        @include lbh-body-m;
        margin: 0;
      }
    }
  }

  &__week {
    background-color: lbh-colour('lbh-grey-4');
    border-radius: 4px;
    @include lbh-rem(padding, 12);

    & > header {
      border-bottom: 1px solid lbh-colour('lbh-grey-3');
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      h3,
      p {
        @include lbh-body-xs;
        line-height: 1;
        color: lbh-colour('lbh-secondary-text');
        margin: 0;
        @include lbh-rem(margin-bottom, 9);
      }

      a {
        @include lbh-body-xs;
        line-height: 1;
        margin: 0;
        @include lbh-rem(margin-bottom, 9);
      }
    }

    &--current {
      & > header {
        h3,
        p {
          color: lbh-colour('lbh-text');
          font-weight: 600;
        }
      }
    }

    &--closed {
      & > header {
        p {
          color: lbh-colour('lbh-text');
          line-height: inherit;
        }

        @include govuk-media-query($from: tablet) {
          p {
            line-height: 1;
          }
        }
      }
    }
  }

  &__operatives {
    @include lbh-rem(margin-top, 9);

    & > header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      h4 {
        @include lbh-body-m;
        color: lbh-colour('lbh-secondary-text');
        width: 100%;
      }

      a {
        @include lbh-rem(font-size, 14);
        display: block;
        margin: 0;
        width: 100%;
      }

      a {
        @include lbh-rem(margin-top, 6);
      }

      nav {
        margin: 0;
        width: 100%;
      }

      @include govuk-media-query($from: tablet) {
        h4 {
          width: auto;
        }

        nav {
          width: auto;

          a:first-child {
            margin-top: 0;
          }
        }

        a {
          text-align: right;
          width: 100%;
        }
      }
    }

    & > ol {
      @include lbh-rem(margin-top, 12);

      li {
        @include lbh-body-s;
      }

      li + li {
        @include lbh-rem(margin-top, 6);
      }

      a {
        display: flex;
        flex-wrap: wrap;
        text-decoration: none;

        span {
          margin: 0;
          width: 100%;
        }

        @include govuk-media-query($from: tablet) {
          span {
            width: auto;
          }
        }
      }

      a:hover {
        text-decoration: underline;
      }
    }

    & > footer {
      @include lbh-rem(margin-top, 12);

      button {
        font-weight: 600;
      }
    }
  }
}
