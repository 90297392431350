.bc-overtime-jobs {
  .govuk-table__header:nth-child(1) {
    width: 14%;
  }

  .govuk-table__header:nth-child(2) {
    width: 24%;
  }

  .govuk-table__header:nth-child(3) {
    width: 35%;
  }

  .govuk-table__header:nth-child(4) {
    width: 15%;
  }

  .govuk-table__header:nth-child(5) {
    width: 12%;
  }
}

.bc-overtime-hours {
  .govuk-table__header:nth-child(1) {
    width: 14%;
  }

  .govuk-table__header:nth-child(2) {
    width: 59%;
  }

  .govuk-table__header:nth-child(3) {
    width: 15%;
  }

  .govuk-table__header:nth-child(4) {
    width: 12%;
  }
}

.bc-overtime-head {
  .govuk-table__header:nth-child(3) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.bc-overtime-row {
  .govuk-table__cell:nth-child(3) {
    padding-left: 10px;
    padding-right: 10px;
  }

  .govuk-table__cell {
    padding-top: 15px;
  }

  .lbh-input,
  .lbh-select {
    margin-top: -10px;
  }
}

.bc-overtime-totals {
  .govuk-table__cell:nth-child(2) {
    padding-left: 10px;
    padding-right: 17px;
  }
}
